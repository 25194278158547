import {
  HttpClient,
  HttpParams,
  HttpUrlEncodingCodec,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HighlightPropertyModel } from '../models/highlights-property.model';
import { PropertyModel, PropertyReturn } from '../models/property.model';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  lang: string = 'pt';
  searchData: any;
  entityId: any = document.getElementById('aux-env-input');

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private translateService: TranslateService
  ) { }

  getId() {
    return Number(this.entityId.value);
  }

  getPopularLocations() {
    const entityId = Number(this.entityId.value);
    return this.http.get<any>(`${environment.apiUrl}/entities/locations/${entityId}`, { withCredentials: true });
  }

  getPropertiesWithoutPortfolio(filters: any, page: any) {
    this.lang = this.cookieService.check('lang')
      ? this.cookieService.get('lang')
      : this.translateService.currentLang
    const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
      .set('entityId', `${this.entityId.value}`)
      .set('language', this.lang)
      .set('filters', filters ? JSON.stringify(filters) : JSON.stringify({}))
      .set('page', page);
    return this.http.get<PropertyReturn>(`${environment.apiUrl}/properties/without-portfolio`, {
      params,
    });
    // return of({ properties: this.properties.slice(page, page + 8), total: this.properties.length });
  }

  getProperties(filters: any, page: any) {
    this.lang = this.cookieService.check('lang')
      ? this.cookieService.get('lang')
      : this.translateService.currentLang
    const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
      .set('entityId', `${this.entityId.value}`)
      .set('language', this.lang)
      .set('filters', filters ? JSON.stringify(filters) : JSON.stringify({}))
      .set('page', page);
    return this.http.get<PropertyReturn>(`${environment.apiUrl}/properties`, {
      params,
    });
    // return of({ properties: this.properties.slice(page, page + 8), total: this.properties.length });
  }

  getEnterprises() {
    this.lang = this.cookieService.check('lang')
      ? this.cookieService.get('lang')
      : this.translateService.currentLang;
    const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
      .set('entityId', `${this.entityId.value}`)
      .set('language', this.lang);

    return this.http.get<any>(`${environment.apiUrl}/properties/enterprises`, { params });
  }

  getPortfolioProperties() {
    this.lang = this.cookieService.check('lang')
      ? this.cookieService.get('lang')
      : this.translateService.currentLang;
    const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
      .set('entityId', `${this.entityId.value}`)
      .set('language', this.lang);

    return this.http.get<any>(`${environment.apiUrl}/properties/portfolio`, { params });
  }

  /**
   * To Review
   * @param unique
   * @returns
   */
  getEnterprise(unique: string) {
    this.lang = this.cookieService.check('lang')
      ? this.cookieService.get('lang')
      : this.translateService.currentLang;

    unique = encodeURIComponent(unique);
    const params = new HttpParams()
      .set('entityId', Number(this.entityId.value))
      .set('language', this.lang);

    return this.http.get<PropertyModel>(
      `${environment.apiUrl}/enterprises/${unique}`,
      { params }
    );
  }

  getAgentProperties() {
    this.lang = this.cookieService.check('lang')
      ? this.cookieService.get('lang')
      : this.translateService.currentLang;
    const params = new HttpParams()
      .set('language', this.lang);
    return this.http.get<PropertyModel>(
      `${environment.apiUrl}/agent-area/properties`,
      { params, withCredentials: true }
    )
  }

  getPropertyByReference(reference: string) {
    this.lang = this.cookieService.check('lang')
      ? this.cookieService.get('lang')
      : this.translateService.currentLang;
    const params = new HttpParams()
      .set('entityId', Number(this.entityId.value))
      .set('language', this.lang);
    reference = encodeURIComponent(reference);
    return this.http.get<PropertyModel>(
      `${environment.apiUrl}/properties/${reference}`,
      { params }
    );
  }

  getHighlightsProperties() {
    this.lang = this.cookieService.check('lang')
      ? this.cookieService.get('lang')
      : this.translateService.currentLang;
    const params = new HttpParams()
      .set('entityId', Number(this.entityId.value))
      .set('language', this.lang);

    return this.http.get<HighlightPropertyModel[]>(
      `${environment.apiUrl}/properties/highlights`,
      { params }
    );
  }

  getLatestProperties() {
    this.lang = this.cookieService.check('lang')
      ? this.cookieService.get('lang')
      : this.translateService.currentLang;
    const params = new HttpParams()
      .set('entityId', Number(this.entityId.value))
      .set('language', this.lang);

    return this.http.get<HighlightPropertyModel[]>(
      `${environment.apiUrl}/properties/latest`,
      { params }
    );
  }

  getLocations(search: any = null) {
    search = search.toLowerCase();
    const results: any[] = [];
    if (this.searchData) {
      this.searchData.locations.forEach((district: any) => {
        if (district.name.toLowerCase().includes(search)) {
          results.push({
            name: district.name,
            type: 't-v1-district',
            id: {
              country: district.countryId,
              region: district.regionId,
              district: district.id,
            },
          });
        }
        district.counties.forEach((county: any) => {
          if (county.name.toLowerCase().includes(search)) {
            results.push({
              name: county.name,
              type: 't-v1-county',
              id: {
                country: district.countryId,
                region: district.regionId,
                district: district.id,
                county: county.id,
              },
            });
          }
          county.parishs.forEach((parish: any) => {
            if (parish.name.toLowerCase().includes(search)) {
              results.push({
                name: parish.name,
                type: 't-v1-parish',
                id: {
                  country: district.countryId,
                  region: district.regionId,
                  district: district.id,
                  county: county.id,
                  parish: parish.id,
                },
              });
            }
          });
        });
      });
    }
    return of(results);
  }

  getLocationsAgents(search: any = null) {
    search = search.toLowerCase();
    const results: any[] = [];
    if (!this.searchData) {
      this.getDataToSearch().subscribe(() => {
        if (this.searchData) {
          this.searchData.agentLocations.forEach((district: any) => {
            if (district.name.toLowerCase().includes(search)) {
              results.push({
                name: district.name,
                type: 't-v1-district',
                id: {
                  country: district.countryId,
                  region: district.regionId,
                  district: district.id,
                },
              });
            }
            district.counties.forEach((county: any) => {
              if (county.name.toLowerCase().includes(search)) {
                results.push({
                  name: county.name,
                  type: 't-v1-county',
                  id: {
                    country: district.countryId,
                    region: district.regionId,
                    district: district.id,
                    county: county.id,
                  },
                });
              }
            });
          });
        }
      });

    }
    if (this.searchData) {
      this.searchData.agentLocations.forEach((district: any) => {
        if (district.name.toLowerCase().includes(search)) {
          results.push({
            name: district.name,
            type: 't-v1-district',
            id: {
              country: district.countryId,
              region: district.regionId,
              district: district.id,
            },
          });
        }
        district.counties.forEach((county: any) => {
          if (county.name.toLowerCase().includes(search)) {
            results.push({
              name: county.name,
              type: 't-v1-county',
              id: {
                country: district.countryId,
                region: district.regionId,
                district: district.id,
                county: county.id,
              },
            });
          }
        });
      });
    }
    return of(results);
  }

  getDataToSearch() {
    this.lang = this.cookieService.check('lang')
      ? this.cookieService.get('lang')
      : this.translateService.currentLang;
    const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
      .set('entityId', `${this.entityId.value}`)
      .set('language', this.lang);
    return this.http
      .get<any>(`${environment.apiUrl}/properties/search-data`, {
        params,
      })
      .pipe(tap((data) => (this.searchData = data)));
  }

  getMlsProperties() {
    this.lang = this.cookieService.check('lang')
      ? this.cookieService.get('lang')
      : this.translateService.currentLang;
    const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
      .set('entityId', `${this.entityId.value
        }`)
      .set('language', this.lang);
    return this.http.get<any>(`${environment.apiUrl}/properties/mls`, {
      params, withCredentials: true
    });
  }
}
