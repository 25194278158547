import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { first, of } from "rxjs";
import { environment } from "src/environments/environment";
import { EntityData } from "../models/entity-app.model";

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  entityId: any = document.getElementById('aux-env-input');

  constructor(private http: HttpClient) { }

  getEntityData() {
    const entityId = Number(this.entityId.value);
    return this.http.get<EntityData>(`${environment.apiUrl}/entities/${entityId}`, { withCredentials: true })
      .pipe(first());
    // return of(this.entityData);
  }

  getImtTable() {
    return this.http.get<any>(`${environment.apiUrl}/entities/files/imt-table`);
  }
}
