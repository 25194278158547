import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SettingsRouting } from './settings-routing';

let routes: Routes = [];
// if(environment.template == 'template1') {
routes = [
  SettingsRouting[environment.template]
];
// }

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
