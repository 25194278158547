import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, take, timer } from 'rxjs';
import { EventService } from './event.service';
import { DataminingService } from './datamining.service';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private promptEvent: any;
  cookieValue: BehaviorSubject<boolean> = new BehaviorSubject(false);
  componentInit: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private platform: Platform,
    private cookieService: CookieService,
    private eventService: EventService,
    private dataminingService: DataminingService
  ) { }

  public initPwaPrompt() {
    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        this.setCookie(true);
        event.preventDefault();
        this.promptEvent = event;
      })
    };
    const interval = setInterval(() => {
      if (this.cookieService.check('cookieConsent')) {
        let displayMode = 'browser';
        if (window.matchMedia('(display-mode: standalone)').matches) {
          displayMode = 'standalone';
        };
        localStorage.setItem('display-mode', displayMode);
        this.watchInstall();
        if (!this.getTempCookie()) {
          this.setCookie(false);
          if (this.platform.ANDROID && this.promptEvent) {
            // window.addEventListener('beforeinstallprompt', (event: any) => {
            //   this.setCookie(true);
            //   event.preventDefault();
            //   this.promptEvent = event;
            this.openPromptComponent('android');
            // });
          }
          if (this.platform.IOS || this.platform.SAFARI) {
            if (displayMode != 'standalone') {
              const interval = setInterval(() => {
                if (this.componentInit.value) {
                  this.openPromptComponent('ios');
                  this.setCookie(true);
                  clearInterval(interval);
                }
              }, 300)
            }
          }
        } else {
          if (this.platform.ANDROID) {
            window.addEventListener('beforeinstallprompt', (event: any) => {
              this.setCookie(true);
              event.preventDefault();
              this.promptEvent = event;
            });
            if (this.platform.IOS || this.platform.SAFARI) {
              if (displayMode != 'standalone') {
                this.setCookie(true);
              }
            }
          }
        }
        clearInterval(interval);
      }
    }, 1000);

  }

  watchInstall() {
    window.matchMedia('(display-mode: standalone)')
      .addListener(event => {
        if (event.matches) {
          if (this.platform.IOS || this.platform.SAFARI || this.platform.FIREFOX) {
            this.sendInstall();
          }
          localStorage.setItem('display-mode', 'standalone');
        } else {
          localStorage.setItem('display-mode', 'browser');
        }
      });
  }

  sendInstall() {
    this.dataminingService.sendData(navigator, 'app-install');
  }

  setCookie(value: any) {
    this.cookieValue.next(value);
    this.cookieService.set('PWA-PROMPT', value, 180);
  }

  getCookie() {
    return this.cookieService.get('PWA-PROMPT');
  }

  setTempCookie(value: any) {
    this.cookieService.set('PWA-RECUSE-PROMPT', value, 1);
  }

  getTempCookie() {
    return this.cookieService.check('PWA-RECUSE-PROMPT');
  }

  showPwaPrompt() {
    if (this.getCookie()) {
      if (this.platform.ANDROID) {
        this.openPromptComponent('android');
      }
      if (this.platform.IOS) {
        const isInStandaloneMode = ('standalone' in window.navigator);
        if (!isInStandaloneMode) {
          this.openPromptComponent('ios');
        }
      }
    }
  }

  addToHomeEvent() {

    if (this.platform.ANDROID) {
      if (this.promptEvent)
        this.promptEvent.prompt();
    }
    if (this.platform.IOS) {
      this.openPromptComponent('ios');
    }

  }

  private openPromptComponent(mobileType: 'ios' | 'android') {
    timer(100)
      .pipe(take(1))
      .subscribe(() => this.eventService.broadcast('open-install-app', { data: { mobileType, promptEvent: this.promptEvent } }));
  }
}
