<div [style.display]="currentPage == '' ? 'none' : null " class="mobile-navigation">
    <ul>
        <li [ngClass]="currentPage == '/' ? 'active' : null" class="list-navigation navigation-1">
            <a routerLink="/">
                <span class="icon-navigation">
                    <ion-icon src="assets/new-icons/home-outline.svg"></ion-icon>
                </span>
                <span class="text-navigation">{{'MENUITEMS.HOME.TEXT' | translate}}</span>
            </a>
        </li>
        <li *ngIf="!isPropertyPage" (click)="getLocation()" [ngClass]="currentPage == '/nearby' ? 'active' : null"
            class="list-navigation navigation-2">
            <button class="button-none">
                <span class="icon-navigation">
                    <ion-icon src="assets/new-icons/nearby.svg"></ion-icon>
                </span>
                <span class="text-navigation">{{'t-near' | translate}}</span>
            </button>
        </li>
        <li [ngClass]="buttonOpen ? 'selected-button' : null" *ngIf="isPropertyPage" (click)="clickButton()"
            class="list-navigation property-contact-nav navigation-2 active">
            <button class="button-none">
                <span class="icon-navigation d-flex align-items-center justify-content-center">
                    <ion-icon class="close-icon" src="assets/new-icons/add-outline.svg"></ion-icon>
                    <ion-icon class="operator-icon" src="assets/new-icons/operator.svg"></ion-icon>
                </span>
                <span class="text-navigation">{{'t-contact' | translate}}</span>
            </button>
        </li>
        <li [ngClass]="currentPage == '/saved-properties' ? 'active' : null" class="list-navigation navigation-3">
            <a routerLink="/saved-properties">
                <span class="icon-navigation">
                    <ion-icon src="assets/new-icons/heart-outline.svg"></ion-icon>
                </span>
                <span class="text-navigation">{{'MENUITEMS.SAVED.TEXT' | translate}}</span>
            </a>
        </li>
        <template class="indicator-navigation"></template>
    </ul>
</div>