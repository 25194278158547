<ng-template #cookieOffCanvas let-offcanvas>
  <div [ngClass]="moreOptions ? 'more-options': null"
    class="offcanvas-header align-items-center d-flex justify-content-center">
    <div [ngClass]="{'logo-white': logoWhite}" class="entity-logo">
      <img [src]="cookiesLogo ? cookiesLogo : entityLogo" width="80" height="80" alt="Logo">
    </div>
    <h4 *ngIf="!moreOptions" class="offcanvas-title mt-5" id="offcanvas-basic-title">{{'t-cookies-title-1' |
      translate}}</h4>
    <h4 *ngIf="moreOptions" class="offcanvas-title mt-5" id="offcanvas-basic-title">{{'t-cookies-title-2' |
      translate}}</h4>
  </div>
  <div *ngIf="!moreOptions" class="offcanvas-body ps-4 pb-0 pt-0">
    <form class="mb-3">
      <div class="row">
        <span class="cookie-text">{{'COOKIE-CONSENT.TEXT' | translate}}
        </span>
      </div>
    </form>
    <div class="button-cookie">
      <button type="button" class="btn cookies-accept-button mb-1"
        (click)="cookiesAccepted(); offCanvasService.dismiss()">{{'COOKIE-ACCEPT.TEXT' | translate}}</button>
      <button type="button" class="btn cookies-more-button" (click)="openMoreOptions()">{{'COOKIE-OPTIONS.TEXT' |
        translate}}</button>
    </div>
  </div>
  <div *ngIf="moreOptions" class="offcanvas-body">
    <form>
      <div class="mb-3 row">
        <ngb-accordion #a="ngbAccordion">
          <ngb-panel id="essential">
            <ng-template ngbPanelHeader>
              <button ngbPanelToggle class="accordion-button">
                <p class="m-0">{{'COOKIES-ESSENTIALS.TEXT' | translate}}</p>
                <div class="option-cookie text-end">
                  <div class="form-check form-switch">
                    <input class="form-check-input" disabled type="checkbox" checked role="switch"
                      id="flexSwitchCheckDefault">
                  </div>
                </div>
              </button>
            </ng-template>
            <ng-template ngbPanelContent>
              {{'t-cookies-essentials' | translate}}
            </ng-template>
          </ngb-panel>
          <ngb-panel id="functional">
            <ng-template ngbPanelHeader>
              <button ngbPanelToggle class="accordion-button">
                <p class="m-0">{{'COOKIES-FUNCTIONAL.TEXT' | translate}}</p>
                <div class="option-cookie text-end">
                  <div class="form-check form-switch">
                    <input (change)="valueChange('functional', $event)" class="form-check-input" type="checkbox" checked
                      role="switch" id="flexSwitchCheckDefault">
                  </div>
                </div>
              </button>
            </ng-template>
            <ng-template ngbPanelContent>

              {{'t-cookies-functionals' | translate}}
            </ng-template>
          </ngb-panel>
          <ngb-panel id="performance">
            <ng-template ngbPanelHeader>
              <button ngbPanelToggle class="accordion-button">
                <p class="m-0">{{'COOKIES-PERFORMANCE.TEXT' | translate}}</p>
                <div class="option-cookie text-end">
                  <div class="form-check form-switch">
                    <input (change)="valueChange('performance', $event)" class="form-check-input" type="checkbox"
                      checked role="switch" id="flexSwitchCheckDefault">
                  </div>
                </div>
              </button>
            </ng-template>
            <ng-template ngbPanelContent>
              {{'t-cookies-performance' | translate}}
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </form>
    <div class="text-center">
      <button type="button" class="btn cookies-accept-button"
        (click)="customCookieAccept();offCanvasService.dismiss()">{{'COOKIES-CONFIRM.TEXT' |
        translate}}</button>
    </div>
  </div>
</ng-template>

<ng-template #cookieModal let-modal>
  <div class="modal-header bg-light p-3">
    <h5 *ngIf="!moreOptions" class="modal-title" id="userModalLabel">{{'t-cookies-title-1' | translate}}</h5>
    <h5 *ngIf="moreOptions" class="modal-title" id="userModalLabel">{{'t-cookies-title-2' | translate}}</h5>
  </div>
  <div *ngIf="!moreOptions" class="modal-body cookie-modal-body">
    <form>
      <div class="row">
        <div [ngClass]="{'logo-horizontal': logoType === 'horizontal'}" class="cookie-entity-img text-center mb-3">
          <img [ngClass]="{'logo-white': logoWhite}" alt="Entity Logo" src="{{cookiesLogo ? cookiesLogo : entityLogo}}">
        </div>
        <p class="cookie-text text-center">{{'COOKIE-CONSENT.TEXT' | translate}}
        </p>
      </div>
    </form>
    <div class="button-cookie">
      <button type="button" class="btn cookies-accept-button mb-1"
        (click)="cookiesAccepted(); modalService.dismissAll()">{{'COOKIE-ACCEPT.TEXT' | translate}}</button>
      <button type="button" class="btn cookies-more-button" (click)="openMoreOptions()">{{'COOKIE-OPTIONS.TEXT' |
        translate}}</button>
    </div>
  </div>
  <div *ngIf="moreOptions" class="modal-body">
    <form>
      <div class="mb-3 row">
        <ngb-accordion #a="ngbAccordion">
          <ngb-panel id="essential">
            <ng-template ngbPanelHeader>
              <button ngbPanelToggle class="accordion-button">
                <p class="m-0">{{'COOKIES-ESSENTIALS.TEXT' | translate}}</p>
                <div class="option-cookie text-end">
                  <div class="form-check form-switch">
                    <input class="form-check-input" disabled type="checkbox" checked role="switch"
                      id="flexSwitchCheckDefault">
                  </div>
                </div>
              </button>
            </ng-template>
            <ng-template ngbPanelContent>
              {{'t-cookies-essentials' | translate}}
            </ng-template>
          </ngb-panel>
          <ngb-panel id="functional">
            <ng-template ngbPanelHeader>
              <button ngbPanelToggle class="accordion-button">
                <p class="m-0">{{'COOKIES-FUNCTIONAL.TEXT' | translate}}</p>
                <div class="option-cookie text-end">
                  <div class="form-check form-switch">
                    <input (change)="valueChange('functional', $event)" class="form-check-input" type="checkbox" checked
                      role="switch" id="flexSwitchCheckDefault">
                  </div>
                </div>
              </button>
            </ng-template>
            <ng-template ngbPanelContent>
              {{'t-cookies-functionals' | translate}}
            </ng-template>
          </ngb-panel>
          <ngb-panel id="performance">
            <ng-template ngbPanelHeader>
              <button ngbPanelToggle class="accordion-button">
                <p class="m-0">{{'COOKIES-PERFORMANCE.TEXT' | translate}}</p>
                <div class="option-cookie text-end">
                  <div class="form-check form-switch">
                    <input (change)="valueChange('performance', $event)" class="form-check-input" type="checkbox"
                      checked role="switch" id="flexSwitchCheckDefault">
                  </div>
                </div>
              </button>
            </ng-template>
            <ng-template ngbPanelContent>
              {{'t-cookies-performance' | translate}}
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </form>
    <div class="text-center">
      <button type="button" class="btn cookies-accept-button"
        (click)="customCookieAccept(); modalService.dismissAll()">{{'COOKIES-CONFIRM.TEXT' |
        translate}}</button>
    </div>
  </div>
</ng-template>
