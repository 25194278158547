import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  constructor(private http: HttpClient) { }

  sendSubscriptionToBackend(subscription: PushSubscription, key: string) {
    const payload = { subscription: subscription.toJSON(), key: key };
    return this.http.post(`${environment.apiUrl}/push/subscription`, payload);
  }

  getKey() {
    return this.http.get<string>(`${environment.apiUrl}/push/keys`);
  }
}
