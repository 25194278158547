import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private placeholder: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public placeholderValue: Observable<boolean>;

  constructor() {
    this.placeholderValue = this.placeholder.asObservable();
  }

  setLoading(loading: boolean) {
    !this.getLoading() && loading ? $('#status').fadeIn() && $('#preloader').fadeIn() : $('#status').fadeOut() && $('#preloader').delay(200).fadeOut('slow');
    this.loading.next(loading);
  }

  getLoading(): boolean {
    return this.loading.value;
  }

  setPlaceholder(loading: boolean) {
    this.placeholder.next(loading);
  }

  getPlaceholder(): Observable<boolean> {
    return of(this.placeholder.value);
  }
}