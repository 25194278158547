import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyPrintComponent } from './property-print.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { QRCodeModule } from 'angularx-qrcode';



@NgModule({
  declarations: [
    PropertyPrintComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    IonicModule,
    QRCodeModule
  ],
  exports: [PropertyPrintComponent]
})
export class PropertyPrintModule { }
