import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { EventService } from './services/event.service';
import { NavigationService } from './services/navigation.service';
import { PwaService } from './services/pwa.service';
import { Subscription } from 'rxjs';
import { ChatService } from './services/chat.service';
import { EntityService } from './services/entity.service';
import { EntityData } from './models/entity-app.model';
import { DataminingService } from './services/datamining.service';
import { LanguageService } from './services/languages.service';
import * as CryptoJS from 'crypto-js';
import { PushService } from './services/push.service';
import { NotificationsService } from './services/notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  title = 'app';
  template: any;
  valueInput: any;
  savedImages: any[] = [];
  color: any = '';
  openPrint: boolean = false;
  propertyToPrint: any;
  entityData!: EntityData;
  qrCodeLink: any = 'https://demo.imodigi.com';
  entityDataSubscription!: Subscription;
  entityId: any = document.getElementById('aux-env-input');


  constructor(
    // private swUpdate: SwUpdate,
    private navigationService: NavigationService,
    private pwaService: PwaService,
    private eventService: EventService,
    private entityService: EntityService,
    private authService: AuthService,
    private chatService: ChatService,
    private route: ActivatedRoute,
    private router: Router,
    private dataminingService: DataminingService,
    private languagesService: LanguageService,
    private pushService: PushService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.getEntityData();
    this.languagesService.initLanguages();
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.active?.postMessage('checkCache');
      });
    }

    this.verifyOldUrl();
    this.checkApp();
    this.navigationService.startHistory();
    this.template = environment.template;
    this.eventService.subscribe(
      'app-saved-images',
      (images) => (this.savedImages = images)
    );
    this.eventService.subscribe('chat-disconnected', () => this.initChat());
    this.initTemplate();
    this.initChat();
    this.getImtTable();
    this.pushNotifications();
    if (!location.href.includes('localhost')) {
      this.notificationsService.connectedNotification();
    }
  }

  ngAfterViewInit(): void {
    let session: any = sessionStorage.getItem('session');
    if (!session) {
      session = new Date() + window.navigator.userAgent;
      session = CryptoJS.SHA256(session).toString();
      sessionStorage.setItem('session', session);
      this.dataminingService.sendData(window.navigator, 'new-session');
    }
  }

  pushNotifications() {
    // if ('Notification' in window) {
    //   Notification.requestPermission().then(permission => {
    //     if (permission === 'granted') {
    //       navigator.serviceWorker.ready.then(registration => {
    //         registration.pushManager.getSubscription().then(sub => {
    //           if (!sub) {
    //             this.pushService.getKey().subscribe((key: string) => {
    //               registration.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: key })
    //                 .then(subscription => {
    //                   // Envie a inscrição para o backend
    //                   this.pushService.sendSubscriptionToBackend(subscription, key).subscribe()
    //                 })
    //                 .catch(error => console.error('Erro ao se inscrever para notificações', error));
    //             })
    //           }
    //         });
    //       });
    //     }
    //   });
    // }
  }

  getImtTable() {
    const imt = sessionStorage.getItem('imtTable');
    if (!imt) {
      this.entityService.getImtTable().subscribe({
        next: (data) => {
          sessionStorage.setItem('imtTable', JSON.stringify(data));
        },
      });
    }
  }

  getEntityData() {
    this.entityDataSubscription = this.entityService.getEntityData().subscribe({
      next: (data) => {
        this.color = data.mainColor;
        localStorage.setItem('entityData', JSON.stringify(data));
        this.entityData = data;
        this.updateColors(data);
        this.setOrgJSONLd();
      },
      error: (err) => {
        console.log(err);
        if (localStorage.getItem('entityData')) {
          const data: any = localStorage.getItem('entityData');
          this.entityData = JSON.parse(data);
          this.setOrgJSONLd();
          this.color = JSON.parse(data)?.mainColor;
          this.updateColors(this.entityData);
        }
      },
    });
  }

  setOrgJSONLd() {
    if (this.entityData) {
      const medias = [];
      if (this.entityData.facebook) {
        medias.push(this.entityData.facebook);
      };
      if (this.entityData.instagram) {
        medias.push(this.entityData.instagram);
      }
      const contentOrg = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "image": this.entityData.logo,
        "url": document.head.baseURI,
        "sameAs": medias,
        "logo": this.entityData.logo,
        "name": this.entityData.name,
        "description": this.entityData.name,
        "telephone": this.entityData.phone,
        "email": this.entityData.email
      };
      const contentWeb = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "name": this.entityData.name,
        "alternateName": this.entityData.name,
        "url": document.head.baseURI
      };
      const el: any = document.getElementById('orgjsonld');
      el.innerHTML = JSON.stringify(contentOrg);
      const el2: any = document.getElementById('webjsonld');
      el2.innerHTML = JSON.stringify(contentWeb);
    }
  }

  verifyOldUrl() {
    this.route.queryParams.subscribe((param) => {
      if (param['c'] && param['p']) {
        let entityId: any = Number(this.entityId.value);
        this.router.navigateByUrl(
          `/property/shared-property/${entityId}-${param['c']}-${param['p']}`
        );
      }
    });
  }

  initChat() {
    this.authService.currentUser.subscribe((user) => {
      if (user) this.chatService.openConnection(user);
    });
  }

  checkApp() {
    window.addEventListener('appinstalled', (event) => {
      this.pwaService.setCookie(false);
      this.pwaService.sendInstall();
    });
  }

  updateColors(data: any) {
    if (this.color && this.color != '') {
      const mainColor = this.color;
      const secondColor = '#2a2a2a';
      const headerColor = data.headerColor
        ? data.headerColor
        : mainColor + 'CC';
      const metaThemeColor = document.querySelector('meta[name=theme-color]');
      metaThemeColor?.setAttribute('content', mainColor);
      document.documentElement.style.setProperty('--main-color', mainColor);
      document.documentElement.style.setProperty('--second-color', secondColor);
      document.documentElement.style.setProperty('--header-color', headerColor);
      document.documentElement.style.setProperty('--card-color', data.cardColor ? data.cardColor : data.headerWhite ? mainColor : headerColor);
      const iconsColor = data.iconFooterColor;
      iconsColor ? document.documentElement.style.setProperty('--icons-color', iconsColor) : document.documentElement.style.setProperty('--icons-color', mainColor);
      if (this.entityData?.backgroundColor) {
        if (this.entityData?.backgroundColor == 'header') {
          document.documentElement.style.setProperty('--bg-mobile', headerColor);
          const metaThemeColor1 = document.querySelector(
            'meta[name=theme-color]'
          );
          metaThemeColor1?.setAttribute('content', headerColor);
        } else {
          document.documentElement.style.setProperty('--bg-mobile', this.entityData?.backgroundColor);
          const metaThemeColor1 = document.querySelector(
            'meta[name=theme-color]'
          );
          metaThemeColor1?.setAttribute('content', this.entityData?.backgroundColor);
        }
      } else {
        document.documentElement.style.setProperty('--bg-mobile', mainColor);
      }
    } else {
      const mainColor = '#38C';
      const secondColor = '#2a2a2a';
      const headerColor = '#38CC';
      const metaThemeColor = document.querySelector('meta[name=theme-color]');
      metaThemeColor?.setAttribute('content', mainColor);
      document.documentElement.style.setProperty('--main-color', mainColor);
      document.documentElement.style.setProperty('--second-color', secondColor);
      document.documentElement.style.setProperty('--header-color', headerColor);
      document.documentElement.style.setProperty('--card-color', headerColor);
      document.documentElement.style.setProperty('--icons-color', mainColor)
      if (this.entityData?.backgroundColor && this.entityData?.backgroundColor == 'header') {
        document.documentElement.style.setProperty('--bg-mobile', headerColor);
        const metaThemeColor1 = document.querySelector(
          'meta[name=theme-color]'
        );
        metaThemeColor1?.setAttribute('content', headerColor);
      } else {
        document.documentElement.style.setProperty('--bg-mobile', mainColor);
      }
    }
    if (this.entityData?.logoSize) {
      document.documentElement.style.setProperty(
        '--logo-size',
        this.entityData?.logoSize + 'px'
      );
    }
    if (this.entityData?.paddingLogo && this.entityData?.paddingLogo != 0) {
      document.documentElement.style.setProperty(
        '--padding-logo',
        this.entityData?.paddingLogo + 'px'
      );
    }
    if (
      this.entityData?.scrollPaddingLogo &&
      this.entityData?.scrollPaddingLogo != 0
    ) {
      document.documentElement.style.setProperty(
        '--scroll-padding-logo',
        this.entityData?.scrollPaddingLogo + 'px'
      );
    }
  }

  initTemplate() {
    switch (this.template) {
      case 'template1':
        this.initTemplate1();
        break;
      default:
        this.initTemplate1();
    }
  }
  initTemplate1() {
    document.body.classList.add('homepage-9');
    document.body.classList.add('hp-6');
    document.body.classList.add('homepage-1');
  }

  ngOnDestroy(): void {
    this.chatService.closeConnection();
    this.entityDataSubscription.unsubscribe();
    window.removeEventListener('appinstalled', () => { });
  }
}
