import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from 'src/app/services/event.service';
import { LeadsService } from 'src/app/services/leads.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  entityData!: any;
  year: any = new Date();
  logoType: any;
  otherLogo: any;
  emailNewsletter: FormGroup;
  submited: boolean = false;
  showSuccessMessage: boolean = false;
  showFooter: boolean = true;
  fullEntityName: any;

  constructor(
    private leadsService: LeadsService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private eventService: EventService
  ) {
    this.emailNewsletter = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  ngOnInit(): void {
    this.year = this.year.getFullYear();
    this.eventService.subscribe('show-footer', value => this.showFooter = value);
    const data: any = localStorage.getItem('entityData');
    this.entityData = JSON.parse(data);
    this.fullEntityName = this.entityData?.fullEntityName;
    this.otherLogo = this.entityData.footerLogo ? this.entityData.footerLogo : null;
    this.logoType = this.entityData.logoType ? this.entityData.logoType : 'horizontal';
  }

  get form() { return this.emailNewsletter.controls }

  checkIfIsPhone(text: string) {
    const phoneTexts = ['1', '2', '3', '4', '5'];
    const cellTexts = ['6', '7', '8', '9'];
    if (cellTexts.some(str => text.toString().startsWith(str) || text.toString().startsWith('+351 ' + str) || text.toString().startsWith('351 ' + str))) {
      return this.translateService.instant('t-mobile-call');
    } else if (phoneTexts.some(str => text.toString().startsWith(str) || text.toString().startsWith('+351 ' + str) || text.toString().startsWith('351 ' + str))) {
      return this.translateService.instant('t-phone-call');
    } else {
      return;
    }
  }

  getSocialLinks(link: string) {
    if (link.startsWith('http')) {
      return link;
    } else {
      return 'https://' + link;
    }
  }

  submitNewsletters() {
    this.submited = true;
    if (this.emailNewsletter.invalid) {
      return;
    } else {
      this.leadsService.sendNewsletterSubscription(this.form['email'].value).subscribe({
        next: (result: any) => {

          this.showSuccessMessage = true;
          setTimeout(() => {
            this.showSuccessMessage = false;
          }, 4000);
          this.submited = false;
          this.form['email'].setValue('');
        },
        error: err => {
          if (err?.error?.message == 'exists') {
            this.form['email'].setErrors(
              { emailExists: true }
            )
          } else {
            this.form['email'].setErrors(
              { customError: true }
            )
          }
        }
      })
    }
  }

}
