<app-header></app-header>
<div [ngClass]="{'h-100': mobile}" class="animation-tag" [@routeAnimations]="mobile && prepareRoute(outlet) ? prepareRoute(outlet): 'HomePage'">
  <router-outlet #outlet="outlet"></router-outlet>
</div>
<app-mobile-nav-menu *ngIf="mobile"></app-mobile-nav-menu>
<app-footer></app-footer>
<app-cookie-consent></app-cookie-consent>
<app-login-warning></app-login-warning>
<app-install-app></app-install-app>
<!-- START PRELOADER -->
<div *ngIf="mobile" [ngClass]="loadingService.getLoading() ? 'd-block': 'd-none' " id="preloader">
  <div id="status">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="status-mes"></div>
  </div>
</div>
<!-- END PRELOADER -->
