import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Socket } from 'ngx-socketio2';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends Socket {
  lang: any;
  constructor(private http: HttpClient, private translateService: TranslateService) {
    super({
      url: `${environment.notificationUrl}`,
      options: {
        withCredentials: true,
        autoConnect: false,
        path: `${environment.notifictionPath}`
      }
    });
    setTimeout(() => {
      this.lang = this.translateService.currentLang;
      this.translateService.onLangChange.asObservable().subscribe(data => {
        this.lang = data.lang;
        // console.log(this.lang)
      })
    },);



  }

  notifyLeads(data: any) {
    this.emit('notify_leads', { n: data, e: this.getId(), lang: this.lang });
  }

  getId() {
    const entityId: any = document.getElementById('aux-env-input');
    return Number(entityId.value);
  }

  connectedNotification() {
    this.connect();
  }
}
