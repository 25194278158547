import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { EntityService } from 'src/app/services/entity.service';
import { EventService } from 'src/app/services/event.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.css']
})
export class CookieConsentComponent implements OnInit, AfterViewInit {

  cookie: any;
  moreOptions: boolean = false;
  optionsSelected: any;
  entityLogo: any;
  logoType: any = 'rounded';
  logoWhite: boolean = false;
  cookiesLogo: any;

  @ViewChild('cookieOffCanvas') cookieConsent: any;
  @ViewChild('cookieModal') cookieModal: any;

  constructor(
    public offCanvasService: NgbOffcanvas,
    private cookieService: CookieService,
    public modalService: NgbModal
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    const entityData: any = localStorage.getItem('entityData');
    const data = JSON.parse(entityData);
    this.logoType = data.logoType;
    this.logoWhite = data.logoWhite;
    this.cookiesLogo = data?.cookiesLogo ? data?.cookiesLogo : null;
    this.entityLogo = data.logo;
    this.cookie = this.cookieService.get('cookieConsent');
    if (this.cookie == '' || this.cookie == null) {
      if (window.innerWidth < 480) {
        this.openCookieConsent(this.cookieConsent);
      } else {
        this.openCookieModal(this.cookieModal);
      }
    }
  }

  openCookieModal(content: any) {
    this.modalService.open(content, { size: 'md', centered: true, keyboard: false, scrollable: true, backdropClass: 'backdrop-main', backdrop: 'static', modalDialogClass: 'cookie-custom-modal', ariaLabelledBy: 'userModalLabel' });
  }

  cookiesAccepted() {
    const cookieToSet: any = { essentials: true, functional: true, performance: true };
    this.cookieService.set('cookieConsent', JSON.stringify(cookieToSet), 180);
  }

  customCookieAccept() {
    this.cookieService.set('cookieConsent', JSON.stringify(this.optionsSelected), 180);
  }

  valueChange(button: string, event: any) {
    this.optionsSelected[button] = event.target.checked;
  }

  openCookieConsent(content: any) {
    this.offCanvasService.open(content, { position: 'bottom', backdrop: 'static', panelClass: 'cookie-canvas', backdropClass: 'cookie-backdrop', ariaLabelledBy: 'offcanvas-basic-title' });
  }

  openMoreOptions() {
    this.optionsSelected = {
      essentials: true,
      functional: true,
      performance: true
    }
    this.moreOptions = true;
    document.getElementsByClassName('cookie-canvas')[0].classList.add('height-cookies');
  }

}
