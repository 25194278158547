<ng-template #contentAndroid let-modal>
    <div class="modal-header bg-light p-3">
        <h4 class="modal-title text-center w-100" innerHTML="{{'t-install-app-iphone-title' | translate}}"
            id="userModalLabel"></h4>
    </div>
    <div class="modal-body install-app text-center d-grid justify-content-center align-items-center">
        <div class="mb-4"><span class="install-description-ad">{{'t-fast-access-text' | translate}}</span></div>
        <button (click)="continueOnWebSite()" class="btn continue-button mb-3">
            {{'t-continue-website' | translate}}
        </button>
        <button (click)="installPwa()" class="btn install-app-button">
            {{'t-install-app-button' | translate}}
        </button>
    </div>
</ng-template>

<ng-template #contentIOS let-modal>
    <div class="modal-body install-app d-flex p-0 text-center">
        <div class="close-install-modal mb-2">
            <a (click)="continueOnWebSite()" class="float-end">
                <ion-icon src="assets/new-icons/close-outline.svg"></ion-icon>
            </a>
        </div>
        <swiper-container #swiper slides-per-view="1" [pagination]="true" class="p-0 w-100">
            <swiper-slide>
                <div class="install-description mt-5">
                    <h4 [innerHTML]="'t-install-app-iphone-title' | translate"></h4>
                </div>
                <div class="bg-transparent h-100 install-img popup-images pt-1">
                    <!-- <div class="mt-4 alt-text">
                        <span class="fs-6"><strong>{{'t-install-app-iphone-1' | translate}}</strong></span>

                    </div> -->
                    <div class="align-items-center install-icons d-flex m-3 ms-5 mt-4">
                        <ion-icon class="me-4 icon-fast" src="assets/new-icons/time-outline.svg"></ion-icon>
                        <div class="text-start"><span class="icon-text">{{'t-fast-access' |
                                translate}}</span><br><span>{{'t-fast-access-text' | translate}}</span></div>
                    </div>
                    <div class="align-items-center install-icons d-flex m-3 ms-5 mt-4">
                        <ion-icon class="me-4 icon-offline" src="assets/new-icons/cloud-offline-outline.svg"></ion-icon>
                        <div class="text-start"><span class="icon-text">{{'t-offline-access' |
                                translate}}</span><br><span>
                                {{'t-offline-text' | translate}}
                            </span></div>
                    </div>
                    <div class="align-items-center install-icons d-flex m-3 ms-5 mt-4">
                        <ion-icon class="me-4 icon-chat" src="assets/new-icons/chatbubble-outline.svg"></ion-icon>
                        <div class="text-start"><span class="icon-text">{{'t-chat' |
                                translate}}</span><br><span>{{'t-chat-text' | translate}}</span></div>
                    </div>
                    <div class="next-slide-btn">
                        <button (click)="nextSlide(swiper)" class="btn w-50 mt-4 white">{{'t-install-start' |
                            translate}}</button>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="popup-images install-img h-100 pt-1">
                    <a class="popup-img d-flex align-items-center justify-content-center mt-4">
                        <img src="assets/images/pwa/{{lang}}/1.png" class="d-block w-78 ms-5" alt="Install APP Step 1">
                    </a>
                    <div class="next-slide-btn">
                        <button (click)="nextSlide(swiper)" class="btn w-50 mt-4 white">{{'t-install-next' |
                            translate}}</button>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="popup-images install-img h-100 pt-1">
                    <a class="popup-img d-flex align-items-center justify-content-center mt-4"> <img
                            src="assets/images/pwa/{{lang}}/2.png" class="d-block w-75 ms-5" alt="Install APP Step 2">
                    </a>
                    <div class="next-slide-btn">
                        <button (click)="nextSlide(swiper)" class="btn w-50 mt-4 white">{{'t-install-next' |
                            translate}}</button>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="popup-images install-img h-100 pt-1">
                    <a class="popup-img d-flex align-items-center justify-content-center mt-4"> <img
                            src="assets/images/pwa/{{lang}}/3.png" class="d-block w-75 ms-5" alt="Install APP Step 3">
                    </a>
                    <div class="next-slide-btn">
                        <button (click)="continueOnWebSite()" class="btn w-50 mt-4 white">{{'t-install-finish' |
                            translate}}</button>
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
</ng-template>
