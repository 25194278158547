import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { LeadsModel } from '../models/leads.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LeadsService {

  entityId: any = document.getElementById('aux-env-input');

  constructor(private http: HttpClient, private cookieService: CookieService, private translateService: TranslateService) { }

  sendNewsletterSubscription(email: string) {
    const data: LeadsModel = {
      email: email,
      entityId: Number(this.entityId.value),
      contactType: 'Newsletter',
      name: '',
      message: ''
    };
    return this.http.post<any>(
      `${environment.apiUrl}/leads/newsletter`,
      data
    );
  }

  sendContactForm(form: any) {
    const data: LeadsModel = {
      ...form,
      entityId: Number(this.entityId.value),
      contactType: 'Message',
    };
    return this.http.post<any>(
      `${environment.apiUrl}/leads/message-form`,
      data
    );
  }

  sendMessageForm(form: any) {
    const data: LeadsModel = {
      ...form,
      entityId: Number(this.entityId.value),
      contactType: 'Message',
    };
    return this.http.post<any>(
      `${environment.apiUrl}/leads/message-form`,
      data
    );
  }

  sendCall(property: any) {
    const data: LeadsModel = {
      property: property,
      entityId: Number(this.entityId.value),
      contactType: 'Phone',
      message: '',
      name: '',
      email: '',
    };
    return this.http.post<any>(
      `${environment.apiUrl}/leads/message-form`,
      data
    );
  }

  sendPrivateDeals(form: any) {
    const data: LeadsModel = {
      ...form,
      entityId: Number(this.entityId.value),
      contactType: 'Private Deals',
    };
    return this.http.post<any>(
      `${environment.apiUrl}/leads/message-form`,
      data
    );
  }

  sendSellForm(form: any) {
    const data: LeadsModel = {
      ...form,
      entityId: Number(this.entityId.value),
      contactType: 'Sell Property',
    };
    return this.http.post<any>(
      `${environment.apiUrl}/leads/message-form`,
      data
    );
  }

  sendEstimate(form: any) {
    const data: LeadsModel = {
      ...form,
      entityId: Number(this.entityId.value),
      contactType: 'Message',
    };
    return this.http.post<any>(
      `${environment.apiUrl}/leads/message-form`,
      data
    );
  }

  getEstimateData() {
    const lang = this.cookieService.check('lang')
      ? this.cookieService.get('lang')
      : this.translateService.currentLang;
    return this.http.get<any>(`${environment.apiUrl}/leads/estimate-data/${lang}`);
  }
}
