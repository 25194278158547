<!-- START FOOTER -->
<footer *ngIf="showFooter" class="first-footer footer-desktop rec-pro">
  <div class="top-footer">
    <div class="container-fluid">
      <div class="row align-items-start">
        <div class="col-lg-4 col-md-6">
          <div [ngClass]="{'logo-horizontal': logoType === 'horizontal'}" *ngIf="entityData?.logo" class="netabout">
            <a routerLink="/" class="logo">
              <img width="230" src="{{otherLogo ? otherLogo : entityData.logo}}" alt="Logo">
            </a>
            <!-- <span class="entity-name ms-3 mt-1">{{fullEntityName ? fullEntityName : entityData.name}}<br><small *ngIf="entityData?.licenseImo">AMI:
                {{entityData.licenseImo}}</small></span> -->
          </div>
          <div class="contactus">
            <ul>
              <li *ngIf="entityData?.address">
                <div class="info">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  <p class="in-p ms-2" [innerHTML]="entityData.address"></p>
                </div>
              </li>
              <li *ngIf="entityData?.email">
                <div class="info">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  <a href="mailto:{{entityData.email}}">
                    <p class="in-p ms-2 ti">{{entityData.email}}</p>
                  </a>
                </div>
              </li>
              <li class="footer-phone" *ngIf="entityData?.phone">
                <div class="info">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <p class="in-p ms-2">
                    {{entityData.phone}}<br><span class="call-info">{{checkIfIsPhone(entityData?.phone)}}</span></p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex align-items-center justify-content-center">
          <div class="widget manage-data">
            <h3 class="mb-0">{{'FOOTER.USEFUL.TEXT' | translate}}</h3>
            <div class="rec-img">
              <ul class="manage-list footer-list ps-0">
                <li *ngIf="entityData.country === 'Portugal'" class="mb-2"><a
                    routerLink="/alternative-resolution">{{'ALTERNATIVE.DISPUTE.TEXT' | translate}}</a>
                </li>
                <li *ngIf="entityData.country === 'Portugal'" class="mb-2"><a href="https://www.livroreclamacoes.pt"
                    target="_blank">{{'FOOTER.BOOK.TEXT' |
                    translate}}</a></li>
                <li class="mb-2"><a routerLink="/terms-conditions">{{'TERMS-CONDITIONS.TEXT' | translate}}</a></li>
                <li class="mb-2"><a routerLink="/privacy-policy">{{'PRIVACY-POLICY.TEXT' | translate}}</a></li>
                <li class="mb-2"><a routerLink="/manage-data">{{'MANAGE-DATA.TEXT' | translate}}</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div id="newsletter" class="col-lg-4 col-md-6">
          <form [formGroup]="emailNewsletter" (ngSubmit)="submitNewsletters()"
            class="bloq-email mailchimp form-inline d-grid align-items-center justify-content-end">
            <div class="newsletters">
              <h3 class="mb-1">Newsletter</h3>
            </div>
            <label for="subscribeEmail" class="error"></label>
            <div class="email">
              <input type="email" formControlName="email" [ngClass]="{'invalid': submited && form['email'].errors}"
                id="subscribeEmail" name="EMAIL" [placeholder]="'FOOTER.NEWSLETTERS.ENTER-EMAIL.TEXT' | translate">
              <input type="submit" class="subscribe-news" [value]="'FOOTER.NEWSLETTERS.SUBSCRIBE.TEXT' | translate">
            </div>
            <div *ngIf="submited && form['email'].errors" class="errors w-auto float-end mt-3">
              <span *ngIf="form['email'].errors['required']">{{'t-validation-email' | translate}}</span>
              <span *ngIf="form['email'].errors['email']">{{'t-validation-email-2' | translate}}</span>
              <span *ngIf="form['email'].errors['emailExists']">{{'t-email-exists-newsletter' | translate}}</span>
              <span *ngIf="form['email'].errors['customError']">{{'t-generic-error' | translate}}</span>
            </div>
            <div *ngIf="showSuccessMessage" class="w-auto float-end mt-3">
              <span class="subscription-success">{{'t-newsletter-text' | translate}}</span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="second-footer p-0 rec-pro">
    <div class="container-fluid px-4 sd-f">
      <div class="copyright d-grid align-items-center py-4">
        <span class="font-footer">{{year}} © {{'FOOTER.COPYRIGHT.TEXT' | translate }}</span>
        <span class="footer-powered">Powered by <a href="https://imodigi.com" class="white"
            target="_blank">Imodigi®</a></span>
      </div>
      <div *ngIf="entityData.footerImages" class="other-images d-flex align-items-center">
        <img *ngFor="let img of entityData.footerImages" [src]="img" width="100" height="100">
      </div>
      <ul class="netsocials d-flex align-items-center">
        <li *ngIf="entityData?.facebook"><a [href]="getSocialLinks(entityData.facebook)" target="_blank"><i
              class="fa fa-facebook" aria-hidden="true"></i></a></li>
        <li *ngIf="entityData?.linkedin"><a [href]="getSocialLinks(entityData.linkedin)" target="_blank"><i
              class="fa fa-linkedin white" aria-hidden="true"></i></a></li>
        <li *ngIf="entityData?.instagram"><a [href]="getSocialLinks(entityData.instagram)" target="_blank"><i
              class="fab fa-instagram"></i></a></li>
        <li *ngIf="entityData?.youtube"><a [href]="getSocialLinks(entityData.youtube)" target="_blank"><i
              class="fa fa-youtube" aria-hidden="true"></i></a></li>
      </ul>
    </div>
  </div>
</footer>
