import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, delay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { User } from '../models/auth.model';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  returnUrl: any;
  entityId: any = document.getElementById('aux-env-input');

  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService, private translateService: TranslateService) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUserApp')!)
    );
    this.currentUser = this.currentUserSubject.asObservable();
    if (localStorage.getItem('currentUserApp')) {
      setTimeout(() => {
        this.checkUser().subscribe({
          next: () => { },
          error: err => {
            this.removeUserToLocalStorage();
          }
        });
      }, 0);
    }
  }

  /**
   * current user
   */
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   * Check if user token is valid
   * @returns
   */
  checkUser() {
    return this.http.get<any>(`${environment.apiUrl}/check-user`, {
      withCredentials: true,
    });
  }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string) {
    const entityId = Number(this.entityId.value);
    const devices = localStorage.getItem('atd__imo__app');

    return this.http
      .post<{ res: number, access_token?: { token: any, refreshToken: any }, appRef?: any, usersDevice: any }>(
        `${environment.apiUrl}/login`,
        { email, password, entityId, atd__imo__app: devices ? devices : JSON.stringify([]) },
        { withCredentials: true }
      )
      .pipe(
        delay(100),
        tap((data) => {
          if (data.res === 1) {
            const token = data?.access_token;
            localStorage.setItem('access-tkn', token?.token);
            localStorage.setItem('access-rtkn', token?.refreshToken);
            localStorage.setItem('atd__imo__app', data?.usersDevice);
            localStorage.setItem('toast', 'true');
          } else {
            if (data.appRef) {
              localStorage.setItem('code_req_app', data.appRef);
            }
          }
        })
      );
  }

  register(data: any) {
    data = {
      ...data,
      entityId: Number(this.entityId.value),
      lang: this.cookieService.check('lang')
        ? this.cookieService.get('lang')
        : this.translateService.currentLang
    };

    return this.http.post<any>(`${environment.apiUrl}/users/register`, data, {
      withCredentials: true,
    });
  }

  validateRegisterCode(email: string, code: string): any {
    const data = {
      email: email,
      code: code,
      entityId: Number(this.entityId.value),
    };
    return this.http.post<any>(
      `${environment.apiUrl}/users/validate-code`,
      data,
      { withCredentials: true }
    );
  }

  validateLoginCode(code: any) {
    const appR = localStorage.getItem('code_req_app');
    const devices = localStorage.getItem('atd__imo__app');
    const data = {
      code: code,
      entity: Number(this.entityId.value),
      code_req_app: appR,
      usersDevice: devices ? devices : JSON.stringify([])
    };
    return this.http.post<{ success: boolean, access_token: { token: any, refreshToken: any }, usersDevice: any }>(
      `${environment.apiUrl}/verification-code`,
      data,
      { withCredentials: true }
    ).pipe(
      delay(100),
      tap((data: { success: boolean, access_token: { token: any, refreshToken: any }, usersDevice: any }) => {
        if (data.success) {
          const token = data?.access_token;
          localStorage.setItem('access-tkn', token?.token);
          localStorage.setItem('access-rtkn', token?.refreshToken);
          localStorage.setItem('atd__imo__app', data?.usersDevice);
          localStorage.setItem('toast', 'true');
        }
      })
    );;
  }

  sendCodeToChangePass(email: string) {
    const data = {
      email: email,
      entityId: Number(this.entityId.value),
      lang: this.cookieService.check('lang')
        ? this.cookieService.get('lang')
        : this.translateService.currentLang
    };
    return this.http.post<any>(
      `${environment.apiUrl}/users/change-password/code`,
      data
    );
  }

  changePasswordCode(email: string, newPass: string, code: any) {
    const data = {
      email: email,
      newPass: newPass,
      code: code,
      entityId: Number(this.entityId.value),
    };
    return this.http.post<any>(
      `${environment.apiUrl}/users/change-password/code`,
      data
    );
  }

  changePassword(oldPass: string, newPass: string) {
    const data = {
      oldPass: oldPass,
      newPass: newPass,
    };
    return this.http.post<any>(
      `${environment.apiUrl}/users/change-password`,
      data,
      {
        withCredentials: true,
      }
    );
  }

  refreshCookie() {
    return this.http.get<{ success: boolean, access_token?: { token: any, refreshToken: any } }>(`${environment.apiUrl}/refresh`, {
      withCredentials: true,
    }).pipe(
      tap((data: { success: boolean, access_token?: { token: any, refreshToken: any } }) => {
        if (data.success && data.access_token) {
          const token = data?.access_token;
          localStorage.setItem('access-tkn', token?.token);
          localStorage.setItem('access-rtkn', token?.refreshToken);
        }
      })
    );
  }

  getUser() {
    return this.http.get<User>(`${environment.apiUrl}/user-auth`, {
      withCredentials: true,
    });
  }

  saveUserToLocalStorage(user: User) {
    localStorage.setItem('currentUserApp', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  removeUserToLocalStorage() {
    localStorage.removeItem('currentUserApp');
    this.currentUserSubject.next(null!);
  }

  /**
   * Logout the user
   */
  logout(page: string) {
    this.removeUserToLocalStorage();
    if (['t-chat', 't-change-password', 'MLS'].includes(page)) {
      this.router.navigate(['/']);
    }
    localStorage.removeItem('access-tkn');
    localStorage.removeItem('access-rtkn');
    // location.reload();
    // return this.http.post<any>(`${environment.apiUrl}/logout`, {}, { withCredentials: true })
    //     .pipe(tap(() => this.removeUserToLocalStorage()));
  }

  /***
   * Manage Data
   */

  sendAccountInfo(email: any) {
    const data = {
      email: email,
      entityId: Number(this.entityId.value),
      lang: this.cookieService.check('lang')
        ? this.cookieService.get('lang')
        : this.translateService.currentLang
    };
    return this.http.post<any>(
      `${environment.apiUrl}/manage-data/account-info`,
      data
    );
  }

  sendCodeToDelete(email: any) {
    const data = {
      email: email,
      entityId: Number(this.entityId.value),
      lang: this.cookieService.check('lang')
        ? this.cookieService.get('lang')
        : this.translateService.currentLang
    };
    return this.http.post<any>(
      `${environment.apiUrl}/manage-data/send-code`,
      data,
      {
        withCredentials: true,
      }
    );
  }

  deleteAccount(email: any, code: string) {
    const data = {
      email: email,
      code: code,
      entityId: Number(this.entityId.value),
    };
    return this.http.post<any>(
      `${environment.apiUrl}/manage-data/delete-account`,
      data,
      {
        withCredentials: true,
      }
    );
  }

  resendVerificationCode() {
    const appR = localStorage.getItem('code_req_app');
    const entity = Number(this.entityId.value);
    return this.http.get<any>(
      `${environment.apiUrl}/resend-verification-code/${entity}/${appR}`,
      {
        withCredentials: true,
      }
    );
  }

  getToken() {
    const token = localStorage.getItem('access-tkn');
    const refresh = localStorage.getItem('access-rtkn');
    if (token && refresh) {
      return {
        token,
        refresh
      }
    } else {
      return null;
    }
  }
}
